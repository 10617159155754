import React, { useState, useEffect } from 'react';
import { useLocation, Link, } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './navigation.css';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
import { Menu, Popover } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
const Navigation = (props) => (
    <div>
        <AuthUserContext.Consumer>
            {authUser =>
                !!authUser[0] ? <NavigationAuth {...props} /> : <NavigationNonAuth />
            }
        </AuthUserContext.Consumer>
    </div>
);



const NavigationAuth = (props) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [isMobile] = useState(false);
    const [menuMode, setMenuMode] = useState(isMobile ? 'inline' : 'horizontal');
    useEffect(() => {
        function handleResize() {
            //console.log(window.innerWidth)
            if (window.innerWidth >= 1000) {
                setMenuMode('horizontal')
            } else {
                setMenuMode('inline')
            }
        }
        window.addEventListener('resize', handleResize)
    });
    
    let location = useLocation();
    //console.log(location.pathname);
    let defaultKey = ['1']
    switch (location.pathname) {
        case '/home':
            defaultKey = ['1']
            break;
        case '/account':
            defaultKey = ['2']
            break;
        default:
            defaultKey = ['1']
    }
    //setMenuMode('horizontal')
    //setMenuVisible(true)
    const menu = [<Menu theme="light" mode={menuMode} defaultSelectedKeys={defaultKey} id="nav" key="nav">

        <Menu.Item key="1"><Link to={ROUTES.HOME}>Dashboard</Link></Menu.Item>

        <Menu.Item key="2"><Link to={ROUTES.ACCOUNT}>Account</Link></Menu.Item>
        <Menu.Item key="3"><SignOutButton /></Menu.Item>
        <Menu.Item disabled={true} style={{ float: 'right' }}> <h1 style={{ fontSize: "27px" }}>{(new Date(props.currentTime)).toLocaleString('de-DE')}</h1> </Menu.Item>
    </Menu>]
    return (
        /*<ul>
            <li>
                <Link to={ROUTES.LANDING}>Landing</Link>
            </li>
            <li>
                <Link to={ROUTES.HOME}>Home</Link>
            </li>
            <li>
                <Link to={ROUTES.ACCOUNT}>Account</Link>
            </li>
            <li>
                <SignOutButton />
            </li>
        </ul>*/
        /*<div className="topnav">
            <Link to={ROUTES.HOME}>Home</Link>
            <Link to={ROUTES.LANDING}>Landing</Link>
            <Link to={ROUTES.ACCOUNT}>Account</Link>
            <SignOutButton />
        </div>*/
        <div>
            {menuMode === 'inline' ? (
                <Popover
                    overlayClassName="popover-menu"
                    placement="bottomRight"
                    content={menu}
                    trigger="click"
                    visible={menuVisible}
                    arrowPointAtCenter
                    onVisibleChange={(visible) => { setMenuVisible(visible) }}
                >
                    <MenuOutlined className="nav-phone-icon" /*onClick={setMenuVisible(true)}*/ />
                </Popover>
            ) : null}
            {menuMode === 'horizontal' ? menu : null}
        </div>
    )
};
/*<Menu.Item key="2"><Link to={ROUTES.LANDING}>Landing</Link></Menu.Item>*/
const NavigationNonAuth = () => {
    let location = useLocation();
    //console.log(location.pathname);
    let defaultKey = ['1']
    switch (location.pathname) {
        case '/signin':
            defaultKey = ['1']
            break;
        /*case '/home':
            defaultKey = ['1']
            break;
        case '/account':
            defaultKey = ['3']
            break;*/
        default:
            defaultKey = ['1']
    }
    //console.log(defaultKey)
    return (
        /*<div className="topnav">
            <Link to={ROUTES.LANDING}>Landing</Link>
            <Link to={ROUTES.SIGN_IN}>Sign In</Link>
        </div>*/
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={defaultKey}>
            <Menu.Item key="1"><Link to={ROUTES.SIGN_IN}>Log In</Link></Menu.Item>


        </Menu>
    )
};
/*<Menu.Item key="2"><Link to={ROUTES.LANDING}>Landing</Link></Menu.Item>*/
export default Navigation