import React, { Component } from 'react'
import "./Timer.css"
import { withFirebase } from '../Firebase';
/*const inlineStyle = {
    height: 400
};*/
var getStatus = null;
class Timer extends Component {
    
    constructor(props) {
        super(props);
        //let intervalId = setInterval(this.updateField, 1000); // runs every second.
        //let intervalId2 = setInterval(this.getServiceStatus, 10000); // runs every 10 seconds.
        this.state = {
            //intervalId: intervalId,
            //intervalId2: intervalId2,s
            lastUpdate: null,
            //now: null,
            data: {}
        };


    }

    /*handleButtonClick = () => {
        axios.get(process.env.REACT_APP_RUNNINGSERVER + "/getWeatherBadenBaden").then(response => {
            this.setState({
                weather: response.data + process.env.NODE_ENV
            });
            this.props.getProducts()
        });
    };*/


    setDataGet = () => {
        if(this.props.authUser[1] != null){
            getStatus = this.props.firebase.subscribeToUpdates('data/' + this.props.authUser[1].currentServer)
            getStatus.on('value', (snapshot) => {
                if (snapshot.val() === null || snapshot.val() === '') {
    
                } else {
                    //console.log(snapshot.val())
                    this.setState({
                        response: snapshot.val()
                    });
                    this.setState({
                        data: JSON.parse(this.state.response)
                    });
                    this.setState({
                        lastUpdate: this.state.data.successTime
                    });
                }
            })
        }
        else{
            setTimeout(this.setDataGet, 250)
        }
    }

    componentWillUnmount() {
        //clearInterval(this.state.intervalId)
        clearInterval(this.state.intervalId2)
        getStatus.off()
    }

    componentDidMount() {
        this.setDataGet();
        //this.getServiceStatus()
    }

    renderService = ({ Type, Host, subType, startStatus, nextStartDate, beatStatus, lastBeat, critical }) => {
        //console.log(nextStartDate)
        var nextStartDateObj = new Date(nextStartDate)
        var lastBeatObj = new Date(lastBeat)
        var classname = "grid-item grid-item-ok"
        var showNextStart = false;
        var showLastBeat = false;
        var nextStartCode = '';
        var lastBeatCode = '';
        var statusText = 'Unknown'
        if(beatStatus === 1 && (this.props.authUser[1] != null) && this.props.authUser[1].showWarning){
            beatStatus = 2;
        }
        if (startStatus === 1 && beatStatus === 1) {
            classname = "grid-item grid-item-warning"
            showNextStart = true;
            showLastBeat = true;
            //return (<div key={Type} className={classname}><div style={{fontSize: "2vmin"}}>{Type}<br></br>{Host}</div><div style={{fontSize: "1.5vmin"}}>{subType}</div><div style={{fontSize: "1.5vmin"}}>Next start date: {nextStartDateObj.toLocaleString('de-DE')}</div><div style={{fontSize: "1.5vmin"}}>Last beat: {lastBeatObj.toLocaleString('de-DE')}</div><div style={{fontSize: "3vmin"}}>Warning</div></div>)
        } else if (startStatus === 1) {
            classname = "grid-item grid-item-warning"
            showNextStart = true;
            statusText = 'Warning'
            //return (<div key={Type} className={classname}><div style={{fontSize: "1vw"}}>{Type}<br></br>{Host}</div><div style={{fontSize: "0.7vw"}}>{subType}</div><div style={{fontSize: "0.7vw"}}>Next start date: {nextStartDateObj.toLocaleString('de-DE')}</div><div style={{fontSize: "1.2vw"}}>Warning</div></div>)
        } else if (beatStatus === 1) {
            showLastBeat = true;
            classname = "grid-item grid-item-warning"
            statusText = 'Warning'
            //return (<div key={Type} className="grid-item-warning">{Type} - {Host}<br></br>{subType}<br></br>Last beat: {lastBeatObj.toLocaleString('de-DE')}</div>)
        } else if (beatStatus === 2) {
            //classname = "grid-item-ok"
            showLastBeat = true;
            statusText = "Running"
            //return (<div key={Type} className="grid-item-ok"><div style={{fontSize: "2.3vmin"}}>{Type}<br></br>{Host}</div><div style={{fontSize: "1.5vmin"}}>{subType}</div><div style={{fontSize: "1.5vmin"}}>Last beat: {lastBeatObj.toLocaleString('de-DE')}</div><div style={{fontSize: "3vmin"}}>Running</div></div>)
        }
        else if (startStatus === 2) {
            showNextStart = true;
            statusText = "Sleeping"
            //classname = "grid-item-ok"
            //return (<div key={Type} className="grid-item-ok"><div style={{fontSize: "2.3vmin"}}>{Type}<br></br>{Host}</div><div style={{fontSize: "1.5vmin"}}>{subType}</div><div style={{fontSize: "1.5vmin"}}>Next start date: {nextStartDateObj.toLocaleString('de-DE')}</div><div style={{fontSize: "3vmin"}}>Sleeping</div></div>)
        }
        if (critical === 1) {
            classname = "grid-item grid-item-critical"
            statusText = "Critical"
        }
        if (showNextStart) {
            nextStartCode = <div style={{ fontSize: "0.7vw" }}>Next start date: {nextStartDateObj.toLocaleString('de-DE')}</div>
        }
        /*else{
            nextStartCode = ''
        }*/
        if (showLastBeat) {
            lastBeatCode = <div style={{ fontSize: "0.7vw" }}>Last beat: {lastBeatObj.toLocaleString('de-DE')}</div>
        }
        /*else{
            lastBeatCode = ''
        }*/
        return (<div key={Type} className={classname}><div style={{ fontSize: "1vw" }}>{Type}<br></br>{Host}</div><div style={{ fontSize: "0.6vw" }}>{subType}</div>{nextStartCode}{lastBeatCode}<div style={{ fontSize: "1.2vw" }}>{statusText}</div></div>)
    }
    render() {
        var data = this.state.data
        return (
            <div className="Timer">
                <div className="clearfix">
                    <h1 style={{ float: "left", fontSize: "27px", margin: "5px 0 0 20px" }}>Dashboard {(data != null && data.showString != null) ? " - " + data.showString : ""}</h1>
                    <h1 style={{ float: "right", margin: "5px 20px 0 0px" }}>Last update: {(Math.floor(((this.props.currentTime) - (new Date(this.state.lastUpdate))) / 1000) <= 0) ? "just now" : (this.state.lastUpdate === null ? "not yet updated" : (Math.floor(((this.props.currentTime) - (new Date(this.state.lastUpdate))) / 1000)) + " second(s) ago")}</h1>
                </div>
                <div className="grid-container">
                    {(data != null && data.showString != null) ? (data.services).map(this.renderService) : "Data unavailable"}
                </div>
            </div>
        )
    }
}
/*
<button onClick={this.handleButtonClick}>Get weather in Baden-Baden</button>
                <h1> The weather in Baden-Baden is: {this.state.weather} </h1>*/
/*<h1 style={{ color: this.state.timecolor }}> The timer is now set to: {this.state.time} </h1>
<h1> The response was: {this.state.response} </h1> */

export default withFirebase(Timer);