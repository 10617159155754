import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
/*import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';*/
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {HorizontalLoginForm} from "./form"
const SignInPage = () => (
    <div style={{padding: "20px 0 0 0"}}>
        <h1>Welcome to TBInt Dashboard</h1>
        <SignInForm />
        
    </div>
);
/*<PasswordForgetLink />
        <SignUpLink />*/
const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};



class SignInFormBase extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onSubmit = values => {
        //const { email, password } = this.state;
        const email = values.email;
        const password = values.password;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });
    };
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        const { /*email, password,*/ error } = this.state;
        //const isInvalid = password === '' || email === '';
        return (
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', flexDirection:'column', height: '10vh'}}>
                
                <HorizontalLoginForm onSubmit={this.onSubmit}/>
                {error && <p>{error.message}</p>}
            </div>

                
        );
    };

}

/*
<form onSubmit={this.onSubmit}>
                    <input
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Email Address"
                    />
                    <input
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Password"
                    />
                    <button disabled={isInvalid} type="submit">
                        Sign In
                </button>
                    {error && <p>{error.message}</p>}
                </form>*/

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignInPage;
export { SignInForm };