import React, { Component } from 'react';
import logo from './logo-piccoli.png';
import logotb from './tbint.jpg';
import './index.css';
import { withAuthentication } from '../Session';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import { Layout} from 'antd';



import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
const { Header, Content } = Layout;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      currentTime: new Date().getTime()
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
      },
    );
    setInterval(() => {
      this.setState({
        currentTime : new Date().getTime()
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.listener();
  }


  render() {
    return (
      <Router>
      <div className="App">
        <Layout className="layout" >
          <Header theme="light" id="header" className="header">
          <div className="logotb" ><img className="imglogotb" src={logotb} alt="Logo" /></div>
          <div className="logo" ><img className="imglogo" src={logo} alt="Logo" /></div>
          
          <Navigation currentTime={this.state.currentTime}></Navigation>
          
          </Header>
          <Content style={{ padding: '0 50px' }}>
          
          <div className="site-layout-content">
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
              <Route path={ROUTES.HOME} render={(props) => <HomePage {...props} currentTime={this.state.currentTime}/>} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
            </div>
          
          </Content>
        </Layout>
      </div>
      </Router>
    );
  }
}

export default withAuthentication(App);
