import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                authUser: null,
                preferences: null,
                getStatus: null
            };
        }
        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (authUser) {
                        this.setState({ authUser })
                        this.setState({getStatus: this.props.firebase.subscribeToUpdates('users/' + this.state.authUser.uid)});
                        this.state.getStatus.on('value', (snapshot) => {
                            if (snapshot.val() === null || snapshot.val() === '') {
                                console.log("nope")
                            } else {
                                //console.log(snapshot.val())
                                this.setState({
                                    preferences: snapshot.val()
                                });
                            }
                        })
                    } else {
                        this.setState({ authUser: null })
                    };
                },
            );

        }
        componentWillUnmount() {
            this.listener();
            this.state.getStatus.off();
        }
        render() {
            return (
                <AuthUserContext.Provider value={[this.state.authUser, this.state.preferences]}>
                    <Component {...this.props}/>
                </AuthUserContext.Provider>
            );
        }
    }
    return withFirebase(WithAuthentication);
};
export default withAuthentication;